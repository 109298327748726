.side-menu {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	// background: linear-gradient(90deg, #1b1464 0%, rgba(27, 20, 100, 0.5) 100%);
	// background: linear-gradient(90deg, #1b1464 0%, #1b1464 20%, #1b1464aa 100%);
	background: var(--overlay-background);
	color: var(--color-tone-1);

	padding-bottom: 1rem;

	display: flex;
	flex-direction: column;

	transition: transform 300ms var(--easing), opacity 300ms var(--easing);
	transform: translateX(0px);

	body:not(.menuopen) & {
		// display: none;
		pointer-events: none;
		opacity: 0;
	}

	body:not(.menuopen) & .side-menu-item {
		transform: translateX(-200px);
	}
}

.side-menu-top-bar {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 64px;
	padding: 16px 16px;
	.icon {
		background: rgba(255, 255, 255, 0.2);
		width: 48px;
		height: 48px;
		font-size: 24px;
	}
}

.side-menu-item {
	font-size: 20pt;
	line-height: 21pt;
	padding: 1rem 4rem;
	transition: transform 300ms var(--easing), opacity 300ms var(--easing);
	transform: translateX(0px);

	cursor: pointer;
	* {
		cursor: pointer;
	}
	&:hover {
		background-color: rgba(255, 255, 255, 0.1);
	}
}
