.password-reset {
	width: 600px;
	max-width: 100%;
	text-align: center;
	margin: 2rem auto;
	padding: 0 2rem;
	.error {
		background-color: var(--error-red);
		width: 100%;
		padding: 0.4rem 1rem;
		color: white;
		margin: 1rem 0;
	}
}

.password-email-form {
	text-align: center;
	button {
		margin-left: auto;
		margin-right: auto;
	}
}
