.desktop {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background: var(--color-tone-7);
	display: flex;

	.admin-side-menu {
		width: 400px;
		position: static;
	}

	.page:not(.login),
	.preloader-section {
		width: 100%;
		height: 100%;
		overflow: auto;
	}

	.page-header > .icon {
		display: none;
	}
}

/* Styling for print */
@media print {
	.admin-side-menu {
		display: none;
	}
	.page,
	.desktop,
	.page__content {
		overflow: visible !important;
	}
}
