.card {
	text-align: left;

	border-radius: var(--border-radius);

	border-left: solid 10px var(--red);
	border-top: none;
	border-bottom: none;
	border-right: none;

	align-items: center;
	text-align: left;
	background: var(--color-tone-5);
	margin: 5px 0;
	padding: 1rem 2rem;

	body.blue & {
		background: #fcfdfe;
		color: black;
	}

	h3 {
		margin: 0;
		margin-bottom: 5px;
	}

	a {
		display: block;

		color: var(--red);
		text-decoration: none;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	body.dark & a {
		color: var(--color-tone-4);
	}

	img {
		float: right;
		width: 100px;
		height: 100px;
		object-fit: cover;
		border-radius: 100px;
		margin-right: -20px;
		margin-left: 20px;
		border: solid 1px var(--color-tone-4);
		margin-top: -10px;
		margin-bottom: -10px;
	}

	&--withurl {
		cursor: pointer;
		* {
			cursor: pointer;
		}
	}

	&--withurl:hover {
		border-left-color: var(--blue);

		a {
			color: var(--blue);
		}
	}

	body.dark &--withurl:hover a {
		color: var(--color-tone-4);
		text-decoration: underline;
	}
}
