.google-map-popup {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.7);

	> *:first-child {
		background: var(--app-background);
		padding: 1rem;
		border-radius: var(--border-radius);
		width: calc(100% - 4rem);
	}
}
