@import './themes/default';

// @import './fonts/icomoon/style';
@import './fonts/open-sans/open-sans';
@import './fonts/montserrat/montserrat';

$fa-font-path: './fonts/fontawesome/webfonts';
@import './fonts/fontawesome/fontawesome';
@import './fonts/fontawesome/light';
@import './fonts/fontawesome/solid';
@import './fonts/fontawesome/brands';

@import './components/page';
@import './components/menu';
@import './components/list';
@import './components/contact';
@import './components/preloader';
@import './components/input';
@import './components/list-item';
@import './components/button';
@import './components/switch';
@import './components/dark-mode-switch';
@import './components/log';
@import './components/scroller';
@import './components/card';
@import './components/checkbox-list';
@import './components/active-incident-bar';
@import './components/value-row';
@import './components/side-menu';
@import './components/section-bar';
@import './components/google-map';
@import './components/google-map-popup';
@import './components/add-incident-log';
@import './components/menu-item';
@import './components/preloader-section';
@import './components/offline-banner';
@import './components/admin-side-menu';
@import './components/table';
@import './components/popup';
@import './components/organisations-selector';
@import './components/actioncard';

@import './pages/login';
@import './pages/logs';
@import './pages/map';
@import './pages/incident-reports';
@import './pages/incident-new';
@import './pages/settings';
@import './pages/checklist';
@import './pages/info';
@import './pages/role-allocation';
@import './pages/roles';
@import './pages/contacts';
@import './pages/password-reset';

@import './popups/incident-details-popup';
@import './popups/contactgroup-details-popup';

:root {
	--color-tone-1: #1a1a1b;
	--color-tone-2: #454747;
	--color-tone-3: #a9abad;
	--color-tone-4: #d3d6da;
	--color-tone-3-4: #a9abad;
	--color-tone-5: #edeff1;
	--color-tone-6: #f6f7f8;
	--color-tone-7: #ffffff;

	--border-color: hsl(214, 9%, 88%);

	--error-red: #a3000f;
	// --red: #ff0021;
	--red: #db0000;
	--green: #62bb35;
	--blue: #6128b7;
	--cognitas-blue: #1b1464;
	--yellow: #ebff00;
	--magenta: #ff0098;
	--orange: #ff7a00;

	--light-blue: #0496ff;
	--purple: #c800ff;
	--black: #000000;

	// --menu-green: linear-gradient(180deg, #009974 15%, #439900 100%);
	// --menu-red: linear-gradient(180deg, #e11a61 15%, #fe5f3d 100%);
	// --menu-blue: linear-gradient(180deg, #5404ff 15%, #2d71f4 100%);

	--menu-green: linear-gradient(180deg, #009974 15%, #027166 100%);
	--menu-red: linear-gradient(180deg, #b8144f 15%, #a3081d 100%);
	--menu-blue: linear-gradient(180deg, #5404ff 15%, #4300b0 100%);

	--border-radius: 5px;
	--page-padding-x: 20px;

	--easing: cubic-bezier(0.19, 1, 0.22, 1);

	--title-font: 600 15pt 'Montserrat', sans-serif; //semi-bold
	--subtitle-font: 600 11.5pt 'Montserrat', sans-serif; //semi-bold
	--body-font: normal 11.5pt/15pt 'Open Sans', sans-serif;
	--smaller-font: normal 10pt/15pt 'Open Sans', sans-serif;
	// font: italic small-caps bold 12px/30px Georgia, serif;

	--grey-2: #edeff1;
	--app-background: #edeff1;

	// --shadow-1: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	// --shadow-1: 0 3px 6px rgba(0, 0, 0, 0.09), 0 3px 6px rgba(0, 0, 0, 0.1);

	--shadow-1: 0 2px 3px rgba(0, 0, 0, 0.06), 0 3px 7px rgba(0, 0, 0, 0.09);
	--shadow-2: 0 0px 3px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

	--overlay-background: rgba(0, 0, 0, 0.7);
}

body.dark {
	--color-tone-1: #ffffff;
	--color-tone-2: #f6f7f8;
	--color-tone-3: #edeff1;
	--color-tone-4: #bdbdbd;
	--color-tone-3-4: #a9abad;
	--color-tone-5: #454747;
	--color-tone-6: #313131;
	--color-tone-7: #282828;
	--border-color: transparent;
}

body.blue {
	//
	--overlay-background: rgba(6, 0, 66, 0.95);
	//
	.menu,
	.login,
	.side-menu {
		--color-tone-1: #ffffff;
		--color-tone-2: #f6f7f8;
		--color-tone-3: #edeff1;
		--color-tone-4: #dbddde;
		--color-tone-3-4: #a9abad;
		--color-tone-5: #454747;
		--color-tone-6: #130e4c;
		--color-tone-7: var(--cognitas-blue);
		--border-color: transparent;

		--app-background: linear-gradient(180deg, #566686 0%, var(--cognitas-blue) 50%, #14122f 100%);
	}
}

body {
	--page-header-background: var(--cognitas-blue);
	--page-header-color: white;
	.section-bar {
		background-color: var(--cognitas-blue);
	}
}

// background: linear-gradient(180deg, #2B4984 55.21%, #132760 100%);
// border-radius: 40px;

* {
	box-sizing: border-box;
	user-select: none;
}

html {
	padding: 0;
	margin: 0;
	background-color: lightgrey;
	height: 100%;
	font: var(--body-font);
}

body {
	padding: 0;
	margin: 0;
	width: 100%;
	max-width: 100vw;
	margin: 0 auto;
	background-color: white;
	color: var(--color-tone-1);

	// position: relative;
	// height: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}

h1 {
	text-transform: uppercase;
	font: var(--title-font);
	letter-spacing: 1px;
}
h2 {
	// font-weight: 700;
	// font-size: 20px;
	font: var(--subtitle-font);
	letter-spacing: 1px;
	text-transform: uppercase;
	// text-align: left;
}

h3 {
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 1rem;
	// text-align: left;
}

hr {
	opacity: 1;
	border: 1px solid var(--color-tone-4);
	border-width: 1px 0 0 0;
	width: 100%;
}

.row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	align-content: center;
	width: 100%;
	margin: 0;
	padding: 0;
}

@import './admin';

// .app {
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	right: 0;
// 	bottom: 0;
// 	width: 100%;
// 	height: 100%;
// 	overflow: hidden;
// 	// background: var(--color-tone-7);
// 	background: var(--grey-2);

// 	.page {
// 		position: absolute;
// 		top: 0px;
// 		left: 0;
// 		right: 0;
// 		bottom: 0;
// 		width: 100%;
// 		height: 100%;
// 		overflow: auto;
// 	}

// 	&__triangle {
// 		position: absolute;
// 		top: 0;
// 		left: 0;
// 		right: 0;
// 		bottom: 0;
// 		height: 100%;
// 		width: 100%;
// 		opacity: 0.5;
// 		margin: 0 !important;
// 		path {
// 			fill: #313131;
// 		}
// 	}
// }

.app {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	// background: var(--color-tone-7);
	background: var(--grey-2);

	width: 500px;
	max-width: 100vw;
	margin: 0 auto;
	height: 100%;
	.page {
		position: absolute;
		top: 0px;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		overflow: auto;
	}

	&__triangle {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		height: 100%;
		width: 100%;
		opacity: 0.5;
		margin: 0 !important;
		path {
			fill: #313131;
		}
	}
}

.markdown {
	text-align: left;
	li {
		margin-top: 1rem;
		margin-right: 1rem;
	}
	p {
		img {
			max-width: 100%;
			margin: auto;
		}
	}
}

.icon {
	display: flex;
	align-items: center;
	justify-content: center;
}

.icon--red {
	background-color: var(--red);
}
.icon--green {
	background-color: var(--green);
}
.icon--blue {
	background-color: var(--blue);
}
.icon--purple {
	background-color: var(--purple);
}
.icon--black {
	background-color: var(--black);
}
.icon--light-blue {
	background-color: var(--light-blue);
}
.icon--orange {
	background-color: var(--orange);
}

.light {
	.app__triangle {
		opacity: 0.04;
	}
}

.blue {
	.app__triangle {
		opacity: 0;
	}
}

.swal2-html-container {
	white-space: pre-line;
}
