.section-bar {
	// background-color: var(--green);
	background-color: var(--color-tone-6);
	color: white;
	text-align: center;
	line-height: 1.2rem;
	margin-top: 0;
	margin-left: calc(-1 * var(--page-padding-x));
	margin-right: calc(-1 * var(--page-padding-x));
	padding-left: 3rem;
	padding-right: 3rem;
	padding-top: 7px;
	padding-bottom: 7px;
}
