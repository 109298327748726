.scroller {
	overflow: auto;
	flex-grow: 1;
	width: 100%;
	height: 100%;
	height: 1px;

	overflow-y: auto;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;

	padding-left: var(--page-padding-x);
	padding-right: var(--page-padding-x);
	padding-top: 10px;
	padding-bottom: 10px;
}
