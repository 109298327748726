.list-item {
	border-radius: var(--border-radius);

	// border: solid 2px var(--color-tone-1);
	border-left: solid 10px var(--color-tone-1);

	box-shadow: var(--shadow-1);

	// border-top: none;
	// border-bottom: none;
	// border-right: none;

	// display: grid;
	// grid-template-columns: 53px 1fr 53px;
	display: flex;
	align-items: center;
	text-align: left;
	min-height: 64px;
	background-color: var(--color-tone-5);
	margin: 8px 0;
	flex-shrink: 0;

	// body.blue & {
	background: #fcfdfe;
	color: black;
	// }

	&--disabled {
		opacity: 0.3;
		pointer-events: none;
	}

	select {
		border: solid 1px var(--color-tone-5);
		margin-top: 5px;
	}

	.icon {
		width: 50px;
	}

	&__centre {
		flex-grow: 1;
		flex-shrink: 1;
		width: 1px;
		padding: 0.4rem 0;

		h2 {
			margin: 0;
			font: var(--body-font);
			font-size: 12pt;
			font-weight: 600;

			text-transform: none;
			letter-spacing: normal;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			// color: var(--color-tone-6);
		}
		p {
			margin: 0;
			font-size: 11pt;
			font-weight: 500;
			opacity: 0.8;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	cursor: pointer;
	* {
		cursor: pointer;
	}

	> * {
		margin-left: 1rem;
		margin-right: 1rem;
	}
	.icon:first-child {
		width: 40px;
		height: 25px;
		color: white;
		font-size: 25px;
		margin-right: 5px;
	}
	> .icon-chevron-right {
		margin: 0;
	}
}

.list-item--bold {
	font-weight: bold;
	* {
		font-weight: bold;
	}
}

.list-item--with-icon {
	// border: solid 3px var(--border-color);
	border-left-width: 2px;
	// border: none;

	> .icon:first-child {
		height: 100%;
		background-color: var(--border-color);
		width: 65px;
		margin-left: 0;
	}
	overflow: hidden;
	.list-item__centre {
		margin-left: 0.6rem;
	}
}

.list-item--green {
	border-left-color: var(--green);
}
.list-item--red {
	border-left-color: var(--red);
}
.list-item--blue {
	border-left-color: var(--blue);
}
.list-item--black {
	border-left-color: var(--black);
}
.list-item--light-blue {
	border-left-color: var(--light-blue);
}
.list-item--purple {
	border-left-color: var(--purple);
}
.list-item--orange {
	border-left-color: var(--orange);
}

.list-item--with-icon.list-item--red {
	border-color: var(--red);
	> .icon:first-child {
		background-color: var(--red);
	}
}

.list-item--with-icon.list-item--green {
	border-color: var(--green);
	> .icon:first-child {
		background-color: var(--green);
	}
}

.list-item--with-icon.list-item--blue {
	border-color: var(--blue);
	> .icon:first-child {
		background-color: var(--blue);
	}
}

.list-item--with-icon.list-item--light-blue {
	border-color: var(--light-blue);
	> .icon:first-child {
		background-color: var(--light-blue);
	}
}

.list-item--with-icon.list-item--purple {
	border-color: var(--purple);
	> .icon:first-child {
		background-color: var(--purple);
	}
}

.list-item--with-icon.list-item--black {
	border-color: var(--black);
	> .icon:first-child {
		background-color: var(--black);
	}
}

.list-item--with-icon.list-item--orange {
	border-color: var(--orange);
	> .icon:first-child {
		background-color: var(--orange);
	}
}
