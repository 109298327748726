.checkbox-list {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 1rem;
	// width: fit-content;
	margin-bottom: 1rem;
}

.checkbox-list > label {
	grid-column-start: span 2;
	margin: 0;
}

.checkbox-list__item {
	display: flex;
	align-items: center;
	justify-content: center;
	input {
		width: 1.4rem;
		height: 1.4rem;
		margin: 5px 15px 5px 5px;
	}
	.icon {
		width: 1.4rem;
		height: 1.4rem;
		margin: 5px 15px 5px 5px;
	}
	.icon-check-alt {
		color: var(--green);
	}
	.icon-xmark {
		color: var(--red);
	}
	label {
		text-align: left;
		margin: 0;
		font-size: 0.9em;
	}
	width: 100%;
	// background-color: var(--color, tomato);
	// aspect-ratio: 1/1;
}
