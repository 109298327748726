.login {
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	overflow-x: hidden;

	background: var(--color-tone-7);

	.page__content {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		height: 100%;

		color: var(--color-tone-1);

		input {
			text-align: center;
		}
		button {
			margin: 1rem 0;
		}
	}

	&__content {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		flex-grow: 1;
	}

	&__logo {
		background-image: var(--logo-dark);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
		// height: 220px;
		width: 220px;
		flex-grow: 1;
	}

	&__version,
	&__forgot-link {
		opacity: 0.4;
	}

	&__forgot-link {
		color: inherit;
		text-decoration: none;
		cursor: pointer;
	}
	&__forgot-link:hover {
		text-decoration: underline;
	}

	.spacer {
		flex-grow: 1;
	}

	&__error {
		background: var(--error-red);
		color: white;
		padding: 6px 13px;
		border-radius: 5px;
		font-size: 0.9rem;
		width: 100%;
		margin-top: 5px;
	}

	.preloader-overlay {
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.3s var(--easing);
	}

	&--busy {
		.preloader-overlay {
			opacity: 1;
			pointer-events: all;
		}
	}

	&__title-1 {
		font-weight: 300;
		font-size: 1.8rem;
		margin: 0;
		margin-top: 2rem;
		margin-bottom: 0.5rem;
	}
	&__title-2 {
		font-weight: 300;
		margin: 0;
		margin-bottom: 4rem;
		opacity: 0.9;
	}

	input,
	select,
	option {
		font-size: 1.1rem;
		width: 300px;
		max-width: 100%;
		height: 40px;
		text-align: center;
		margin-bottom: 8px;
	}

	button.button {
		font-weight: bold;
		padding: 0.5rem 3rem;
		height: 40px;
		background: linear-gradient(180deg, #cf048e 0%, #990981 100%);
		font-size: 1.1rem;
	}
	.cognitas-logo {
		height: 100px;
		object-fit: contain;
	}
	.offline-banner {
		display: none;
	}
}

body.dark {
	.login__logo {
		background-image: var(--logo-dark);
	}
}
body.light {
	.login__logo {
		background-image: var(--logo-light);
	}
}

body.blue {
	.login__logo {
		background-image: var(--logo-dark);
	}
}
