.menu-item {
	// width: 90px;
	max-width: 90px;
	width: 24vw;
	// height: 90px;
	aspect-ratio: 1/1;
	background-color: var(--color-tone-5);
	border-radius: calc(var(--border-radius) * 1.5);

	margin: auto;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;
	* {
		cursor: pointer;
	}

	&__label {
		width: 100%;
		text-align: center;
		position: absolute;
		top: calc(100% + 12px);
		opacity: 0.8;
		text-transform: lowercase;
		line-height: 17px;
	}

	&__icon {
		font-size: 50px;
		width: 50px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: var(--color-tone-2);
		object-fit: contain;
	}

	&--disabled {
		opacity: 0.5;
		pointer-events: none;
		cursor: not-allowed;
		* {
			cursor: not-allowed;
		}
	}

	&--green {
		background: var(--menu-green);
		.menu-item__icon {
			color: white;
		}
	}
	&--blue {
		background: var(--menu-blue);
		.menu-item__icon {
			color: white;
		}
	}
	&--red {
		background: var(--menu-red);
		.menu-item__icon {
			color: white;
		}
	}
}

// body.blue {
.menu-item {
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
	background: var(--menu-button-background);
	border-radius: calc(var(--border-radius) * 3);
	.menu-item__icon {
		color: var(--menu-button-icon-colour);
	}

	&__label {
		text-transform: none;
	}
}
.menu-item--bolt {
	// background: var(--red);
	background: linear-gradient(180deg, #dc344a 0%, #b31d30 100%);
	.menu-item__icon {
		color: var(--white);
	}
}
// }
