.logs {
	.page__content {
		text-align: left;
	}

	h2 {
		text-transform: uppercase;
		letter-spacing: 1px;
		font-size: 1.3rem;
	}

	select {
		width: 100%;
		margin-bottom: 1rem;
		box-shadow: var(--shadow-1);
		border: none;
	}

	textarea {
		box-shadow: var(--shadow-1);
	}

	hr {
		width: 100%;
		margin: 1rem 0;
	}

	&__container {
		flex-grow: 1;
		background-color: white;
		border-radius: var(--border-radius);

		display: block;
		margin-bottom: 1rem;
		padding: 0;
		// box-shadow: var(--shadow-1);

		// margin-left: calc(var(--page-padding-x) * -1);
		// margin-right: calc(var(--page-padding-x) * -1);
		// width: calc(100% + var(--page-padding-x) * 2);

		.log {
			background-color: white;
			color: black;
		}
	}

	p {
		text-align: center;
	}
}

.add-incident-log {
	margin-bottom: 1rem;
}

.preloader-section.logs__container {
	display: flex;
}
