.offline-banner {
	background-color: rgba(0, 0, 0, 0.3);
	background-color: rgba(0, 0, 0, 1);
	color: white;

	p {
		margin-top: 2px;
	}
	h2 {
		margin-bottom: 0;
	}
}
.desktop {
	.page {
		position: relative;
	}
	.offline-banner {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
	}
}

.offline {
	.desktop {
		.page {
			a,
			button {
				opacity: 0.3;
				pointer-events: none;
				cursor: not-allowed;
			}
		}
	}
}
