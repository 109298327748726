.popup {
	background-color: var(--color-tone-7);
	border-radius: var(--border-radius);
	position: relative;
	max-height: 96vh;
	overflow: auto;
	display: flex;
	flex-direction: column;

	.preloader-overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(255, 255, 255, 0.8) !important;
	}
}

.popup__overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
}

.popup__content {
	flex-grow: 1;
	overflow: auto;
	padding: 2rem 4rem;
}

.popup__footer {
	display: flex;
	align-items: center;
	justify-content: space-around;
	background-color: var(--color-tone-6);
	padding: 1rem 2rem;
}

.popup__errors {
	width: calc(100% + 8rem);
	margin-left: -4rem;
	margin-right: -4rem;
	margin-top: 0;
	background-color: var(--error-red);
	text-align: center;
	color: white;
	p {
		margin: 0;
		padding: 0.5rem 1rem;
	}
}
