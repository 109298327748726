.admin-side-menu {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	// background: linear-gradient(90deg, #1b1464 0%, rgba(27, 20, 100, 0.5) 100%);
	// background: linear-gradient(90deg, #1b1464 0%, #1b1464 20%, #1b1464aa 100%);
	border-right: solid 1px rgba(0, 0, 0, 0.2);

	padding-bottom: 1rem;

	display: flex;
	flex-direction: column;

	transition: transform 300ms var(--easing), opacity 300ms var(--easing);
	transform: translateX(0px);

	background: var(--cognitas-blue);

	.side-menu-item,
	.icon {
		color: white;
	}

	.side-menu__logo {
		background-image: var(--logo-dark);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
		height: 120px;
		width: 100%;
		margin: 30px auto;
		flex-grow: 0;
	}

	// body:not(.menuopen) & {
	// 	// display: none;
	// 	pointer-events: none;
	// 	transform: translateX(-200px);
	// 	opacity: 0;
	// }

	.side-menu-top-bar {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 64px;
		padding: 16px 16px;
		.icon {
			background: rgba(255, 255, 255, 0.2);
			width: 48px;
			height: 48px;
			font-size: 24px;
		}
	}

	.side-menu-item {
		// fibnt
		font: var(--title-font);
		padding: 1rem 4rem;
		padding-left: 1.4rem;

		cursor: pointer;
		* {
			cursor: pointer;
		}
		&:hover {
			background-color: rgba(255, 255, 255, 0.1);
		}
		display: flex;
		.icon {
			margin-right: 1rem;
		}
	}
}
