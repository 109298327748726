.preloader-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--overlay-background);
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
}

.preloader {
	color: white;
}

.preloader-bricks {
	// --colour1: var(--red);
	// --colour2: var(--blue);
	// --colour3: var(--green);

	// --colour1: white;
	// --colour2: white;
	// --colour3: white;

	--colour1: var(--magenta);
	--colour2: var(--magenta);
	--colour3: var(--magenta);

	/* generated by https://loading.io/ */
	@keyframes ldio-wuc0evm2wbs {
		0% {
			transform: translate(110px, 30px);
		}
		8.3% {
			transform: translate(110px, 110px);
		}
		25% {
			transform: translate(110px, 110px);
		}
		33.3% {
			transform: translate(30px, 110px);
		}
		50% {
			transform: translate(30px, 110px);
		}
		58.3% {
			transform: translate(30px, 30px);
		}
		75% {
			transform: translate(30px, 30px);
		}
		83.3% {
			transform: translate(110px, 30px);
		}
		100% {
			transform: translate(110px, 30px);
		}
	}

	.ldio-wuc0evm2wbs > div {
		position: absolute;
		width: 60px;
		height: 60px;
		border-radius: 6px;
		animation: ldio-wuc0evm2wbs 2s linear infinite;
	}
	.ldio-wuc0evm2wbs > div:nth-child(1) {
		background: var(--colour1);
		animation-delay: -0.5s;
	}
	.ldio-wuc0evm2wbs > div:nth-child(2) {
		background: var(--colour2);
		animation-delay: -1.1666666666666667s;
	}
	.ldio-wuc0evm2wbs > div:nth-child(3) {
		background: var(--colour3);
		animation-delay: -1.8333333333333333s;
	}
	.loadingio-spinner-bricks-jwpk1bb72tj {
		width: 200px;
		height: 200px;
		display: inline-block;
		overflow: hidden;
		// background: #ffffff;
		transform: scale(0.5);
	}
	.ldio-wuc0evm2wbs {
		width: 100%;
		height: 100%;
		position: relative;
		transform: translateZ(0) scale(1);
		backface-visibility: hidden;
		transform-origin: 0 0; /* see note above */
	}
	.ldio-wuc0evm2wbs div {
		box-sizing: content-box;
	}
}
