table {
	display: table;
	table-layout: fixed;
	border-collapse: collapse;
	border-spacing: 0;

	margin: 0;
	padding: 0;

	border: 1px solid var(--color-tone-4);
	font-size: 0.8rem;
	font-weight: 400;
	color: var(--color-tone-1);

	thead {
		display: table-header-group;
		vertical-align: middle;
		border-color: inherit;
		background-color: var(--color-tone-4);
		th {
			font-weight: 700;
			text-transform: uppercase;
			letter-spacing: 1px;
			font-size: 0.8rem;
			padding: 0.5rem;
			text-align: left;
			border-bottom: 1px solid var(--color-tone-4);
			background-color: var(--color-tone-0);
			color: var(--color-tone-1);
		}
	}
	tbody {
		tr {
			display: table-row;
			vertical-align: inherit;
			border-color: inherit;
			td {
				text-align: left;
				padding: 0.5rem;
				// border-bottom: 1px solid var(--color-tone-4);
				background-color: var(--color-tone-0);
				color: var(--color-tone-1);
			}
		}
		tr:nth-child(odd) {
			background-color: var(--color-tone-6);
		}
	}
}

table {
	a {
		margin-right: 1rem;
	}
}
