.contactgroup-details-popup {
	.icon {
		font-size: 2rem;
		width: 4rem;
		height: 4rem;
		border-radius: var(--border-radius);
		margin: auto;
		color: white;
	}
}
