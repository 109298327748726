.dark-mode-switch {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	opacity: 0.2;
	transition: opacity 1s var(--easing);
	margin: 10px;

	&:hover {
		opacity: 1;
	}

	.icon {
		color: black;
		transition: color 1s var(--easing);
		font-size: 20px;
	}

	.react-switch {
		margin: 0 8px 0 6px;
		.react-switch-bg {
			background-color: black !important;
			transition: background-color 1s var(--easing);
			svg {
				display: none;
			}
		}
		.react-switch-handle {
			background-color: white !important;
			transition: background-color 1s var(--easing);
		}
	}
}

.dark {
	.dark-mode-switch {
		opacity: 0.2;

		&:hover {
			opacity: 1;
		}
		.icon {
			color: white;
		}
		.react-switch-bg {
			background-color: white !important;
		}
		.react-switch-handle {
			background-color: black !important;
		}
	}
}
