.actioncard {
	// Two columns
	display: grid;
	grid-template-columns: 1fr 1fr;

	// Default colours:
	--background-colour-1: var(--cognitas-blue);
	--background-colour-2: var(--magenta);
	--text-colour-1: white;
	--text-colour-2: white;
}

.actioncard-panel {
	position: relative;
	// width: 50%;
	// aspect-ratio: 1/1;
	min-height: 200px;
	background-color: grey;
	display: flex;
	flex-direction: column;
	// justify-content: center;
	align-items: center;
	padding-top: 1rem;

	.icon {
		font-size: min(80px, 15vw);
		margin: 1rem;
	}
	&__content {
		font-size: 0.9rem;
		margin: 1rem;
		margin-top: 0;
	}
	&__number {
		position: absolute;
		top: 5px;
		left: 10px;
		font-weight: bold;
		&::after {
			content: '.';
		}
	}
}

.actioncard-panel:nth-child(4n) {
	background-color: var(--background-colour-1);
	color: var(--text-colour-1);
}
.actioncard-panel:nth-child(4n + 1) {
	background-color: var(--background-colour-1);
	color: var(--text-colour-1);
}
.actioncard-panel:nth-child(4n + 2) {
	background-color: var(--background-colour-2);
	color: var(--text-colour-2);
}
.actioncard-panel:nth-child(4n + 3) {
	background-color: var(--background-colour-2);
	color: var(--text-colour-2);
}

// .actioncard-panel:nth-child(2n) {
// 	background-color: var(--cognitas-blue);
// 	color: white;
// }
