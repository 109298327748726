.settings {
	.page__content {
		align-items: center;
	}
	footer {
		width: calc(100% + 2 * var(--page-padding-x));
		margin-left: calc(-1 * var(--page-padding-x));
		margin-right: calc(-1 * var(--page-padding-x));
	}
}
