.row.value-row {
	border-bottom: solid 1px var(--border-color);
	border-left: solid 1px var(--border-color);
	border-right: solid 1px var(--border-color);
}
.row.value-row:last-child {
	margin-bottom: 0.8rem;
}

.value-row {
	&__label {
		font-weight: bold;
		line-height: 2rem;
		background: var(--color-tone-5);
		width: 50%;
		flex-shrink: 1;
		font-size: 0.8rem;
		text-transform: uppercase;
		letter-spacing: 1px;
		padding-left: 1rem;
	}
	&__value {
		padding-right: 1rem;
	}
}
