.contact {
	display: flex;
	// background-color: var(--color-tone-5);
	margin: 0.4rem 0;
	padding-left: 0;
	text-decoration: none;
	color: var(--color-tone-2);
	text-align: left;
	background: var(--color-tone-7);
	border-radius: 99px;
	box-shadow: var(--shadow-1);

	&__left {
		// width: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		// margin-left: -1rem;
		margin-right: 1rem;

		.icon {
			border-radius: 999px;
			// border: solid 1px black;
			padding: 20px;
			// background-color: var(--color-tone-5);
			color: white;
			font-size: 24px;
		}
	}

	&__right {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		flex-grow: 1;
		color: var(--color-tone-1);

		width: 0;

		padding-left: 50px;
		margin-left: -50px;
		// margin-right: calc(var(--page-padding-x) * -1);
		// z-index: -1;
		// background-color: var(--color-tone-5);
		border-top-right-radius: var(--border-radius);
		border-bottom-right-radius: var(--border-radius);
		padding-right: var(--page-padding-x);

		p {
			margin: 5px 0px;
			transform: translateY(3px);
		}
		* {
			margin: 0;
		}
		.contact__name {
			font: var(--body-font);
			font-size: 12pt;
			font-weight: 600;
			line-height: 12px;
			text-align: left;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			margin: 0;
			line-height: 1.2em;
			margin-top: -3px;
			margin-bottom: 3px;
			width: 100%;
		}
		.contact__phone {
			// font-weight: bold;
			opacity: 0.6;
			margin-right: 0.5rem;
		}
		.contact__title {
			opacity: 0.6;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 0;
			flex-grow: 1;
			text-align: right;
			padding-right: 2px;
		}
		.row {
			justify-content: space-between;
			height: 1.3em;
			margin-bottom: 2px;
			flex-wrap: nowrap;
		}
	}
}
