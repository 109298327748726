input {
	margin: 3px 0;
	padding: 0.5rem 1rem;
	border: solid 1px var(--color-tone-3);
	border-radius: var(--border-radius);
	font: var(--body-font);
}

select {
	margin: 3px 0;
	padding: 0.5rem 1rem;
	border: solid 1px var(--color-tone-3);
	border-radius: var(--border-radius);
	font: var(--body-font);
}

label {
	display: block;
	width: 100%;
	text-align: left;
	margin-bottom: 1rem;
	> textarea,
	> input,
	> select {
		margin-top: 5px;
	}
}

input,
select {
	width: 100%;
	border-radius: var(--border-radius);
	border: solid 1px var(--color-tone-3);
}

textarea {
	width: 100%;
	border-radius: var(--border-radius);
	resize: none;
	min-height: 80px;
	border: solid 1px var(--color-tone-3);
	font: var(--body-font);
	padding: 0.5rem 1rem;
}

input,
textarea {
	-webkit-user-select: text;
}

input:disabled,
textarea:disabled {
	border-color: transparent;
	background-color: var(--color-tone-4);
	color: var(--color-tone-1);
	opacity: 1;
	font-weight: 600;
}
