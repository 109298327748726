:root {
	--logo-dark: url('/img/incident360.png');
	--hero-image-1: url('/img/descSchool.jpg');
	--hero-logo-1: url('/img/incident360.png');
	--hero-logo-1: url('/img/desc-logo.png');
}

:root {
	--menu-button-background: linear-gradient(180deg, #ffffff 0%, #dbe9ff 100%);
	--menu-button-icon-colour: #1b1464;
	// box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
}
