.active-incident-bar {
	// background-color: var(--color-tone-6);
	background-color: white;
	margin-left: calc(-1 * var(--page-padding-x));
	margin-right: calc(-1 * var(--page-padding-x));
	padding-left: var(--page-padding-x);
	padding-right: var(--page-padding-x);
	text-align: left;

	margin-bottom: 1rem;

	&__datetime {
		// font-size: 0.9rem;
		color: var(--color-tone-3-4);
	}

	h3 {
		background-color: var(--yellow);
		color: black;
		font-weight: 700;
		text-align: center;
		line-height: 2.4rem;
		margin-top: 0;
		margin-bottom: 0;
		margin-left: calc(-1 * var(--page-padding-x));
		margin-right: calc(-1 * var(--page-padding-x));
		position: relative;
		.icon {
			position: absolute;
			right: 0;
			top: 0;
			height: 100%;
			width: 3rem;
		}
	}

	> * {
		padding-left: var(--page-padding-x);
		padding-right: var(--page-padding-x);
	}

	body.light & {
		border-bottom: solid 1px var(--color-tone-4);
	}
	body.blue & {
		border-bottom: none;
	}

	&__children {
		display: flex;
		justify-content: space-around;
		justify-content: center;
		padding: 0.5rem 1rem;
		button {
			margin: 0 5px;
		}
	}
}

.active-incident-bar:not(.active-incident-bar--expanded) {
	> *:not(h3) {
		display: none;
	}
}

.active-incident-bar.active-incident-bar--inactive {
	h3 {
		background-color: var(--color-tone-6);
		color: var(--color-tone-1);
		margin-bottom: 0;
		font-weight: 600;
	}
}

.active-incident-bar.preloader-section {
	height: 80px;
	flex-grow: 0;
	overflow: hidden;
	margin-left: calc(-1 * var(--page-padding-x));
	margin-right: calc(-1 * var(--page-padding-x));
	width: auto;
	.preloader-bricks {
		display: block;
		zoom: 0.7;
	}
}
