.preloader-section {
	overflow: auto;

	width: 100%;
	height: 1px;
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: var(--border-radius);
}
