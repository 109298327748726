.map {
	.page__content {
		flex-grow: 1;
		overflow: hidden;
	}

	.panzoom-parent {
		overflow: hidden;
		user-select: none;
		touch-action: none;
		// max-height: 80%;

		position: relative;

		flex-grow: 1;
		opacity: 1;
		transition: opacity 1s var(--easing);

		// border-top: solid 1px var(--color-tone-3);
		// border-bottom: solid 1px var(--color-tone-3);

		// display: flex;
		// align-items: center;
		// justify-content: center;
	}
	.map__draggable {
		box-sizing: content-box;
		// width: auto;
		// height: 80%;
	}
	img {
		// object-fit: contain;
		height: 100%;
		// transform: translate(-50%, 0%);
		// width: auto;
	}
	.map__content {
		display: inline-block;
		height: 100%;
	}
	.row {
		padding: calc(var(--page-padding-x) / 2) var(--page-padding-x);
	}
	.map__key {
		position: absolute;
		top: 0;
		left: 0;
		pointer-events: none;
		height: 200px;
	}
}

.map--loading {
	.panzoom-parent {
		opacity: 0;
	}
}
