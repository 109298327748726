$add-incident-log__height: 1.2rem;
$input-border-color: var(--color-tone-4);
$base-line-height: 15px;
$input-disabled-border-colour: var(--color-tone-4);

.add-incident-log {
	> .row {
		align-items: flex-end;
		flex-flow: nowrap;
	}

	&__wrapper {
		flex-grow: 1;
		// border: solid 1px $input-border-color;
		// border-radius: var(--border-radius);
		// overflow: hidden;
		overflow: visible;
	}

	> p {
		margin: 0;
		margin-top: 5px;
		margin-bottom: -5px;
		opacity: 0.8;
	}

	textarea {
		width: 100%;
		border: none;
		display: flex;
		align-items: center;
		line-height: $base-line-height + 2px;
		height: $add-incident-log__height;
		max-height: 7rem;
		min-height: auto;
		resize: none;
		padding-left: 1rem;
		padding-right: 1rem;
		outline: none;
		border-radius: 16px;
	}

	.icon {
		margin-left: 0.5rem;
		background-color: var(--green);
		color: white;
		border-radius: 99px;
		height: 32px;
		width: 32px;
		// box-shadow: var(--shadow-1);

		button {
			margin: 0;
			padding-left: 1rem;
			padding-right: 1rem;
			text-align: center;
			height: $add-incident-log__height;
		}
		.button__text {
			transform: translateY(1px);
		}
	}
}

.add-incident-log--disabled {
	.add-incident-log__wrapper {
		border: solid 1px $input-disabled-border-colour;
	}
}

.add-incident-log:not(.add-incident-log--disabled) {
	.add-incident-log__wrapper {
		&:hover,
		&:focus-within {
			border-color: var(--accent-colour-1);
		}
	}
}
