.checklist {
	--green: #62bb35;
	--red: #ff0021;
	--grey: #8d9f9b;

	// .checklist-item {
	// }
}

.checklist .page__content > .scroller {
	margin-top: -10px;
	margin-left: calc(-1 * var(--page-padding-x));
	margin-right: calc(-1 * var(--page-padding-x));
	width: calc(100% + (2 * var(--page-padding-x)));
}

.checklist-item {
	position: relative;
	overflow: hidden;
	text-align: left;
	border-radius: var(--border-radius);
	border: solid 1px var(--red);
	background: var(--red);
	margin-bottom: 1.5rem;

	box-shadow: var(--shadow-2);
	// border-left-width: 6px;

	&--completed {
		border-color: var(--green);
		background: var(--green);
		.checklist-item__content {
			pointer-events: none;
		}
		.checklist-item__footer {
			background-color: var(--green);
		}
	}
	&--dismissed {
		border-color: var(--grey);
		background: var(--grey);
		.checklist-item__content {
			pointer-events: none;
		}
		.checklist-item__footer {
			background-color: var(--grey);
		}
	}
	&--incomplete {
		.checklist-item__icon--complete {
			opacity: 0.5;
			.checklist-item__content {
				pointer-events: none;
			}
		}
	}
}

.checklist-item:not(.checklist-item--expanded) {
	.checklist-item__content {
		height: 42px;
	}
	p {
		text-overflow: ellipsis;
		overflow: hidden;
		max-height: 100%;
		white-space: nowrap;
	}
}

.checklist-item__content {
	// background-color: var(--color-tone-1);
	// background: linear-gradient(180deg, #ffffff 78.18%, #dceaff 100%);
	// box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	background: var(--color-tone-7);
	color: var(--color-tone-1);

	z-index: 2;
	position: relative;
	padding: calc(var(--page-padding-x) / 2) var(--page-padding-x);
	padding-right: 32px; // To give room for expander
	overflow: hidden;

	p {
		margin: 0;
	}
	ul {
		padding-inline-start: 10px;
	}
	li {
		list-style: none;
		display: flex;
		min-height: 32px;
		padding-top: 5px;
		cursor: pointer;
		// margin-bottom: 5px;
		.icon {
			--size: 21px;
			background: var(--color-tone-4);
			color: white;
			height: var(--size);
			width: var(--size);
			line-height: var(--size);
			font-size: calc(var(--size) * 0.6);
			flex-shrink: 0;
			border-radius: 99px;
			margin-right: 10px;
			transform: translateY(1px);
		}
	}
	li:not(.checklist-item__mini-check--completed):hover {
		.icon {
			border: solid 3px var(--green);
		}
	}
	li.checklist-item__mini-check--completed {
		.icon {
			background: var(--green);
		}
	}
}

.checklist-item__expander {
	position: absolute;
	top: 0px;
	right: 0px;
	padding: 12px 16px;
	color: var(--color-tone-1);
	z-index: 10;
	// margin-right: -1rem;
	// margin-top: -0.5rem;
	// float: right;
}

.checklist-item__footer {
	background-color: var(--red);
	padding: 6px var(--page-padding-x);
	display: flex;
	color: white;

	&-left {
		flex-grow: 1;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		text-align: left;
	}
	&-right {
		flex-grow: 1;
		display: flex;
		// flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		text-align: right;
	}
	p {
		margin: 0;
	}
}

// .checklist-item__footer-right p,
// .checklist-item__footer-left,
// .checklist-item__footer-right,
.checklist-item__time,
.checklist-item__status,
.checklist-item__user {
	text-transform: uppercase;
	font-weight: 700;
	font-size: 0.8rem;
	letter-spacing: 1px;
	opacity: 0.8;
}
.checklist-item__user {
	font-size: 9pt;
	line-height: 11pt;
}
.checklist-item__time {
	font-size: 8pt;
	line-height: 9pt;
	font-weight: 400;
}

// Icons ===========================================================================================

.checklist-item *[class^='checklist-item__icon'] {
	height: 2rem;
	width: 2rem;
	border-radius: 99px;
}

.checklist-item__icon--comment {
	font-size: 1.2rem;
	background-color: transparent;
	color: white;
	cursor: pointer;
}

.checklist-item__icon--not-applicable {
	font-size: 11px;
	background-color: white;
	cursor: pointer;
	&:before {
		content: 'N/A';
		font-family: sans-serif;
		color: black;
		font-weight: bold;
	}
}

.checklist-item__icon--complete {
	font-size: 1.2rem;
	cursor: pointer;
	background-color: white;
	color: var(--green);

	margin-left: 0.5rem;
	&:before {
		transform: translate(1px, 1px);
	}
}
