.log {
	// background: var(--color-tone-6);
	text-align: left;
	padding: 0.5rem 1rem;
	// margin-bottom: 1rem;
	border-radius: var(--border-radius);
	// border: solid 1px var(--border-color);
	// border-left: 5px solid var(--blue);
	color: var(--color-tone-7);

	&__icon {
		height: 100%;
		width: calc(10px + 1rem);
	}

	&__dot {
		width: 10px;
		height: 10px;
		border-radius: 24px;
		background-color: black;
	}

	&__dot--green {
		background-color: var(--green);
	}
	&__dot--red {
		background-color: var(--red);
	}
	&__dot--blue {
		background-color: var(--blue);
	}
	&__dot--black {
		background-color: var(--black);
	}
	&__dot--light-blue {
		background-color: var(--light-blue);
	}
	&__dot--purple {
		background-color: var(--purple);
	}
	&__dot--orange {
		background-color: var(--orange);
	}

	.log__left {
		flex-grow: 1;
	}

	&__author,
	&__role,
	&__time {
		font: var(--smaller-font);
		opacity: 0.6;
	}
	&__author {
	}
	&__role {
	}
	&__time {
		// font-weight: bold;
	}
	.row {
		// margin: 0.1rem 0 0.4rem 0;
		// margin-bottom: 0.4rem;
	}
	&__content {
		// background-color: var(--color-tone-5);
		border-radius: var(--border-radius);
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		padding: 0.5rem 1rem;
		margin-right: -1rem;
		margin-bottom: -0.5rem;
		margin-top: -0.3rem;

		margin-left: calc(10px + 1rem);
		padding-left: 0;

		word-break: break-word;
	}
	&__location-detail {
		font-size: 0.9em;
		opacity: 0.6;
		text-align: right;
		flex-grow: 1;
	}
	&__location-button {
		background: var(--green);
		display: flex;
		color: white;
		border-radius: var(--border-radius);
		padding: 0.5rem 1rem;
		max-width: 450px;
		.icon {
			margin-right: 1rem;
		}
	}
}
