.page {
	width: 600px;
	max-width: 100vw;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	text-align: center;
	background-color: var(--grey-2);

	&__content {
		display: flex;
		flex-direction: column;
		align-items: stretch;

		padding-left: var(--page-padding-x);
		padding-right: var(--page-padding-x);
		padding-top: 10px;
		flex-grow: 1;

		overflow-x: hidden;

		footer {
			display: flex;
			justify-content: center;
			align-items: center;

			background-color: var(--color-tone-6);
			padding: 0.5rem 1rem;
			margin-left: calc(var(--page-padding-x) * -1);
			margin-right: calc(var(--page-padding-x) * -1);
			.button {
				margin: 0 1rem;
			}
		}
	}

	&--nopadding {
		.page__content {
			padding: 0;
		}
		footer {
			margin: 0;
		}
	}
}

.desktop .page-header {
	background-color: var(--color-tone-6);
	color: var(--color-tone-1);
}

.page-header {
	height: 80px;
	// display: grid;
	// grid-template-columns: 66px 1fr;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	text-align: left;
	// width: calc(100% + var(--page-padding-x) * 2);
	width: 100%;
	flex-shrink: 0;

	// margin-left: calc(var(--page-padding-x) * -1);
	margin-right: calc(var(--page-padding-x) * -1);
	padding-left: var(--page-padding-x);
	padding-right: var(--page-padding-x);
	border: none;

	body.blue & {
		border-bottom: none;
	}

	.icon:first-child {
		margin-left: calc(var(--page-padding-x) * -1);
	}

	h1 {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 100%;
		flex-grow: 1;
	}

	.icon {
		// margin: auto 30px;
		// font-size: 1.2rem;
		font-size: 24px;
		cursor: pointer;
		height: 100%;
		width: 100%;
		width: 80px;
		flex-shrink: 0;
	}

	&__content {
		width: 0;
		flex-grow: 1;

		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;

		> * {
			margin: 0;
		}
	}
}

.page-header,
.page__content footer {
	background: var(--page-header-background);
	color: var(--page-header-color);
}
