.organisations-selector {
	padding: 4px 24px;
	background-color: var(--color-tone-4);
	margin-bottom: 1rem;

	label {
		display: flex;
		align-items: center;
		margin: 0;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-size: 0.8rem;
	}
	select {
		margin-left: 16px;
		width: 500px;
		max-width: 100%;
	}
}
