.incident-new,
.incident-details {
	.page__content {
		text-align: left;
		flex-grow: 1;
	}
	p {
		text-align: center;
	}
}
