button:not(.google-map button) {
	font-weight: 400;
	letter-spacing: 2px;
	text-transform: uppercase;
	font-size: 11pt;
	font-weight: 700;
	background-color: var(--green);
	color: white;
	padding: 10px 20px;
	border: none;
	border-radius: var(--border-radius);
	position: relative;
	box-shadow: var(--shadow-1);

	height: 2.5em;

	display: flex;
	justify-content: space-evenly;
	align-items: center;

	cursor: pointer;
	* {
		cursor: pointer;
	}

	> .icon {
		height: 100%;
		object-fit: contain;
	}

	&.button--primary {
		max-width: 86%;
		width: 370px;
		border-radius: calc(var(--border-radius) * 3);
		height: 3em;

		background: var(--red);
		color: white;
		border: 1px solid var(--red);
		margin: 0 auto;
		font-weight: 600;
		font-size: 18px;
		// box-shadow: 0 5px 10px #00000080;

		box-shadow: var(--shadow-1);
	}
}
