.menu {
	background: var(--color-tone-7);

	.page__content {
		flex-grow: 1;

		color: var(--color-tone-1);
	}

	&__hero {
		height: 28vh;
		width: 100%;
		position: relative;
		z-index: -1;
		display: flex;
		align-items: center;
		justify-content: center;

		.menu__hero__background {
			background-color: var(--color-tone-7);
			background-image: var(--hero-image-1);
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: -1;
			// background-blend-mode: hard-light;
		}

		.menu__hero__shade {
			background-color: var(--color-tone-7);
			// background: linear-gradient(to top, #1b1464, #1b146400);
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: -1;
			opacity: 0.4;
		}

		.menu__hero__logo {
			height: 50%;
			background-image: var(--hero-logo-1);
			width: 50%;
			// object-fit: contain;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}
	}

	&__grid {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		align-items: center;
		justify-content: center;
		max-width: 95%;
		width: 420px;
		margin: 0 auto;
		aspect-ratio: 4 / 3;
		// margin: 0 auto; // remove this to have full width (and can then potentially remove grid-gap)
		// grid-gap: 1vw;
		// grid-template-rows: repeat(3, 150px); // include this to force row height
		// flex-grow: 1; // include this to stretch to height of container
	}

	button.button {
		margin: 0.5rem;
		// font-size: 18px;
		// margin: 0 auto;
		// max-width: 86%;
		// width: 370px;
		// border-radius: calc(var(--border-radius) * 3);
		// box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
		// height: 3em;
	}

	button:not(.google-map button).button--primary {
		background: white;
		color: var(--red);
		border: 1px solid var(--red);
		margin: 0.5rem auto;
	}

	a {
		color: white !important;
		position: absolute;
		bottom: 10px;
		left: 0;
		right: 0;
	}

	@media (max-width: 490px) {
		.button--admin {
			display: none;
		}
	}

	h1 {
		letter-spacing: 3px;
	}

	.active-incident-bar {
		margin: 0;
		margin-bottom: 0.5rem;
		background: none;
		text-align: center;
	}

	.preloader-section.active-incident-bar {
		width: calc(100% + (2 * var(--page-padding-x)));
	}

	.menu-top-bar {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	}
}

.menu-top-bar {
	width: 100%;
	padding: 16px 16px;
	display: flex;
	justify-content: space-between;
	.icon {
		background: rgba(255, 255, 255, 0.2);
		width: 48px;
		height: 48px;
		font-size: 24px;
		border-radius: var(--border-radius);
	}
}

@media (max-width: 450px) {
	.menu {
		&__grid {
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 1vw;
		}
		.menu-item {
			&__label {
				font-size: 13px;
				line-height: 12px;
			}
		}
	}
}
